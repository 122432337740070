import React from "react"
import styled from "styled-components"
import { PageRendererProps, navigate } from "gatsby"
import headerBackgroundImage from "@/images/hackathon-header.jpg"
import stepsBackgroundImage from "@/images/hackathon-steps-bg.jpg"
import logoImage from "@/images/logo.svg"
import FadeIn from "@/components/FadeIn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCompass, faChartLine, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import SEO from "@/components/SEO"

const title = 'Azure Light-up'
const description = 'Azure Light-up は、 Microsoft Azure に精通したエキスパートと共に数日で MVP を構築するプログラムです。'

type HackathonPageProps = PageRendererProps

const CaseBlock: React.FC<{ corporate: string; title: string; href: string }> = ({ corporate, title, href }) => (
  <a href={href} className="case" target="_blank">
    <div className="text">
      <div className="corporate">{corporate}</div>
      <div className="title">{title}</div>
    </div>
    <div className="more">
      詳しく見る
      <div className="icon">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  </a>
)

const Hackathon: React.FC<HackathonPageProps> = ({ location }) => (
  <>
    <SEO title={title} description={description} pathname={location.pathname} />
    <Root>
      <Header>
        <Background></Background>
        <Content>
          <FadeIn offset={0} delay={200}>
            <TitleMain>
              Azure Light-up
            </TitleMain>
          </FadeIn>
          <FadeIn offset={0} delay={600}>
            <TitleSub>
              BY
              <Logo src={logoImage} />
            </TitleSub>
          </FadeIn>
        </Content>
      </Header>
      <AboutSection>
        <FadeIn offset={0} delay={1000}>
          <AboutContent>
            <About>
              <AboutTitle>
                <AboutIcon>
                  <FontAwesomeIcon icon={faCompass} />
                </AboutIcon>
                Azure Light-up は<br />どのようなプログラムか
              </AboutTitle>
              <AboutDescription>
                数日間のハッカソンでビジネスの継続的デリバリをサポートできるクラウドネイティブなデザインのシステムへの変革をサポートします。
                プロジェクトの課題やニーズに合わせて Microsoft Azure や関連テクノロジーに精通したエキスパートを中心としたチームを結成し、数日でお客様と一緒に MVP（Minimum Viable Product）を構築します。
              </AboutDescription>
            </About>
            <About>
              <AboutTitle>
                <AboutIcon>
                  <FontAwesomeIcon icon={faChartLine} />
                </AboutIcon>
                Web App Modernization <br /> への最短距離
              </AboutTitle>
              <AboutDescription>
                ゼンアーキテクツは Azure 黎明期より App Service や Cosmos DB に代表される PaaS / Serverless スタックを積極的に活用したクラウドネイティブなアーキテクチャを数多く手がけてきました。
                Azure Light-up に参加することで、お客様の Web アプリを Azure を使って超短期間でモダナイゼーションするためのノウハウをトランスファーすることができます。
              </AboutDescription>
            </About>
          </AboutContent>
        </FadeIn>
      </AboutSection>
      <FlowSection>
        <FlowTitle>Azure Light-up の実施ステップ</FlowTitle>
        <FlowSectionBackground>
          <FlowSectionBackgroundFilter />
        </FlowSectionBackground>
        <Steps>
          <Step>
            <StepNumber>01</StepNumber>
            <FadeIn offset={50} delay={200}>
              <StepTitle>
                アイデアソンの実施
              </StepTitle>
              <StepDescription>
                ビジネス要求やプロジェクトの課題を解決するアイデアについてディスカッションし3日間の目標を設定します
              </StepDescription>
            </FadeIn>
          </Step>
          <Step>
            <StepNumber>02</StepNumber>
            <FadeIn offset={50} delay={200}>
              <StepTitle>
                アーキテクチャ案の構想
              </StepTitle>
              <StepDescription>
                アイデアソンの結果を踏まえたアーキテクチャを構想してブループリントを描きます
              </StepDescription>
            </FadeIn>
          </Step>
          <Step>
            <StepNumber>03</StepNumber>
            <FadeIn offset={50} delay={200}>
              <StepTitle>
                主要技術の検証
              </StepTitle>
              <StepDescription>
                構想したアーキテクチャで最もキーとなる要素について実際に手を動かして実装・検証します
              </StepDescription>
            </FadeIn>
          </Step>
          <Step>
            <StepNumber>04</StepNumber>
            <FadeIn offset={50} delay={200}>
              <StepTitle>
                主要機能の実装
              </StepTitle>
              <StepDescription>
                MVP（Minimum Viable Product）を構成する機能について複数チームで分担して実装を進めます
              </StepDescription>
            </FadeIn>
          </Step>
          <Step>
            <StepNumber>05</StepNumber>
            <FadeIn offset={50} delay={200}>
              <StepTitle>
                レビュー・評価
              </StepTitle>
              <StepDescription>
                アイデアソンで設定した目標を達成できるか、構想したアーキテクチャを実現できるかの観点でレビューを実施します
              </StepDescription>
            </FadeIn>
          </Step>
        </Steps>
      </FlowSection>
      <CaseSection>
        <h2 className="heading">
          <span className="main">Cases</span>
          <span className="sub">お客様事例</span>
        </h2>
        <div className="cases">
          {CaseBlock({
            corporate: '株式会社コミュニティオ',
            title: 'リリース3年でエンタープライズにアプローチ。Azureへの移行でスタートアップが受ける恩恵と技術的課題の解消方法',
            href: 'https://customers.microsoft.com/en-us/story/1490446327899998761-communitio-professional-services-azure-jp-japan'
          })}
          {CaseBlock({
            corporate: '富士フイルムソフトウエア株式会社',
            title: '「ハッカソン」で社外の知見を積極的に活用! 富士フイルムソフトウエアが実践するサービス開発の理想形',
            href: 'https://customers.microsoft.com/EN-US/story/817886-fujifilm-software-co-ltd-jp-japan'
          })}
        </div>
      </CaseSection>
      <ContactSection>
        <Contact onClick={() => navigate('/contacts?hackathon=true')}>お問い合わせ</Contact>
      </ContactSection>
    </Root>
  </>
)

const Root = styled.div``

const Header = styled.div`
  position: relative;
  background-color: #000;

  @media ${(props) => props.theme.minMdUp} {
    height: 600px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    height: 400px;
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${headerBackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: brightness(30%);
`

const Content = styled.div`
  @media ${(props) => props.theme.maxSmDown} {
    padding: 50px 40px 0;
  }
  @media ${(props) => props.theme.minMdUp} {
    padding-top: 80px;
    padding-right: 15px;
    padding-left: 15px;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: ${(props) => props.theme.textLightColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleMain = styled.h1`
  font-family: 'Teko', sans-serif;
  white-space: nowrap;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 100px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 50px;
  }
`

const TitleSub = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${(props) => props.theme.minMdUp} {
    margin-top: 20px;
    font-size: 20px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 30px;
    font-size: 14px;
  }
`

const Logo = styled.img`
  @media ${(props) => props.theme.minMdUp} {
    height: 20px;
    margin-left: 14px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    height: 14px;
    margin-left: 10px;
  }
`

const AboutSection = styled.section`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 0 80px 0;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 40px 30px;
  }
`

const About = styled.div`
  @media ${(props) => props.theme.minMdUp} {
    padding: 0 15px;
    width: 50%;
  }
`

const AboutContent = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: auto;
  
  @media ${(props) => props.theme.maxSmDown} {
    flex-wrap: wrap;
  }
  @media ${(props) => props.theme.minMdUp} and ${(props) => props.theme.maxMdDown} {
    width: 750px;
  }
  @media ${(props) => props.theme.minLgUp} and ${(props) => props.theme.maxLgDown} {
    width: 970px;
  }
  @media ${(props) => props.theme.minXLUp} {
    width: 1170px;
  }
`

const AboutTitle = styled.h2`
  line-height: 1.5;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.primaryDarkColor};

  @media ${(props) => props.theme.minMdUp} {
    font-size: 24px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 18px;

    ${About} + ${About} & {
      margin-top: 40px;
    }
  }
`

const AboutIcon = styled.div`
  line-height: 1;
  color: ${(props) => props.theme.primaryColor};

  @media ${(props) => props.theme.minMdUp} {
    font-size: 80px;
    margin-right: 30px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 40px;
    margin-right: 15px;
  }
`

const AboutDescription = styled.div`
  text-align: justify;
  line-height: 1.75;

  @media ${(props) => props.theme.minMdUp} {
    margin-top: 20px;
    padding-right: 15px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 20px;
  }
`

const FlowSection = styled.section`
  position: relative;
  overflow: hidden;
`

const FlowSectionBackground = styled.div`
  background-color: ${(props) => props.theme.secondaryLightColor};
  background-image: url(${stepsBackgroundImage});
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: grayscale(70%) blur(3px);
  transform: scale(1.2);
`

const FlowSectionBackgroundFilter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
`

const FlowTitle = styled.h2`
  font-weight: normal;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  background-color: ${(props) => props.theme.primaryDarkColor};
  color: ${(props) => props.theme.secondaryLightColor};
  z-index: 1;
  position: relative;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 20px;
  }
`

const Steps = styled.div`
  padding: 20px 0;
  margin: 0 auto;

  @media ${(props) => props.theme.minMdUp} and ${(props) => props.theme.maxLgDown} {
    padding-right: 15px;
    padding-left: 15px;
    width: 700px;
  }
  @media ${(props) => props.theme.minXLUp} {
    padding-right: 15px;
    padding-left: 15px;
    width: 900px;
  }
`

const Step = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:first-of-type:before {
    content: '';
    position: absolute;
    background-color: #fff;
    bottom: 100%;
  }

  @media ${(props) => props.theme.minMdUp} {
    padding-left: 150px;

    &:after {
      left: 80px;
      width: 10px;
      top: 57.5px;
      bottom: -40px;
    }

    &:first-of-type:before {
      width: 10px;
      left: 80px;
      top: -60px;
    }

    &:last-of-type:after {
      bottom: -60px;
    }
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding-left: 100px;

    &:after {
      left: 47.5px;
      width: 5px;
      top: 37.5px;
      bottom: -7.5px;
    } 

    &:first-of-type:before {
      width: 5px;
      left: 47.5px;
      top: -40px;
    }

    &:last-of-type:after {
      bottom: -40px;
    }
  }
`

const StepNumber = styled.div`
  position: absolute;
  top: -12.5px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left top, #409EA6 0%, #2C6C8B 51%, #203F56 100%);
  color: #fff;
  font-weight: 700;

  @media ${(props) => props.theme.minMdUp} {
    border: 10px solid;
    left: 50px;
    width: 70px;
    height: 70px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    border: 5px solid;
    left: 25px;
    width: 50px;
    height: 50px;
  }
`

const StepTitle = styled.h3`
  color: ${(props) => props.theme.primaryDarkColor};

  @media ${(props) => props.theme.minMdUp} {
    margin-top: 40px;
    font-size: 20px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 20px;
    font-size: 18px;
  }
`

const StepDescription = styled.p`
  line-height: 1.75;
  text-align: justify;

  @media ${(props) => props.theme.minMdUp} {
    margin-bottom: 40px;
    margin-top: 20px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    margin-bottom: 20px;
    margin-top: 15px;
    padding-right: 30px;
  }
`

const CaseSection = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 0;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 50px 0;
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(props) => props.theme.primaryColor};

    @media ${(props) => props.theme.minMdUp} {
      margin-bottom: 20px;
    }

    @media ${(props) => props.theme.maxSmDown} {
      margin-bottom: 10px;
    }

    .main {
      font-family: 'Teko', sans-serif;
      font-weight: 500;

      @media ${(props) => props.theme.minMdUp} {
        font-size: 100px;
        line-height: 80px;
      }

      @media ${(props) => props.theme.maxSmDown} {
        font-size: 60px;
        line-height: 50px;
      }
    }

    .sub {
      letter-spacing: 0.1em;

      @media ${(props) => props.theme.minMdUp} {
        font-size: 14px;
      }

      @media ${(props) => props.theme.maxSmDown} {
        font-size: 12px;
      }
    }
  }

  .cases {
    @media ${(props) => props.theme.minMdUp} {
      max-width: 720px;
      padding: 20px 30px;
    }

    @media ${(props) => props.theme.maxSmDown} {
      padding: 20px 30px 0;
    }
  }

  .case {
    display: block;
    text-decoration: none;
    color: inherit;
    background-color: #f8f8f8;
    border-radius: 4px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.01), 0 6px 30px rgba(0, 0, 0, 0.03);
    transition: box-shadow .3s;

    & + .case {
      @media ${(props) => props.theme.minMdUp} {
        margin-top: 40px;
      }

      @media ${(props) => props.theme.maxSmDown} {
        margin-top: 30px;
      }
    }

    .corporate {
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      background-color: ${(props) => props.theme.primaryDarkColor};
      color: ${(props) => props.theme.secondaryLightColor};
      padding: 4px 12px;
      border-radius: 20px;

      @media ${(props) => props.theme.minMdUp} {
        margin-bottom: 14px;
      }

      @media ${(props) => props.theme.maxSmDown} {
        margin-bottom: 10px;
      }
    }

    .text {
      @media ${(props) => props.theme.minMdUp} {
        padding: 30px 30px 26px;
      }

      @media ${(props) => props.theme.maxSmDown} {
        padding: 24px 20px 18px;
      }
    }

    .title {
      line-height: 1.5;
      padding: 0 4px;
      text-align: justify;

      @media ${(props) => props.theme.maxSmDown} {
        font-size: 14px;
      }
    }

    .more {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: 6px solid #fff;
      color: ${(props) => props.theme.primaryDarkColor};

      @media ${(props) => props.theme.minMdUp} {
        font-size: 14px;
        padding: 14px 30px;
      }

      @media ${(props) => props.theme.maxSmDown} {
        font-size: 12px;
        padding: 12px 20px;
      }
    }

    .icon {
      margin: 0 4px 0 10px;
      transition: transform .2s;
    }

    &:hover {
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.01), 0 6px 30px rgba(0, 0, 0, 0.05);

      .icon {
        transform: translateX(4px);
      }
    }
  }
`

const ContactSection = styled.div`
  background-color: ${(props) => props.theme.secondaryLightColor};
  text-align: center;

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 0;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 60px 0;
  }
`

const Contact = styled.button`
  background: linear-gradient(to left, #409EA6 0%, #2C6C8B 51%, #203F56 100%);
  color: #fff;
  letter-spacing: 0.1em;
  padding: 15px 50px;
  border-radius: 40px;
  transition: box-shadow 0.4s;
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  border: none;

  &:focus {
    outline: none;
  }

  @media ${(props) => props.theme.minMdUp} {
    font-size: 30px;

    &:hover {
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 20px;
  }
`

export default Hackathon
